<template>
  <div class="relative p-6 overflow-hidden text-white md:p-4 lg:p-8 rounded-3xl"
       :class="`bg-${backgroundGradient}`"
  >
    <NuxtImg v-if="backgroundImage && size === 'desktop'"
             :src="backgroundImage"
             :alt="title || ''"
             class="absolute inset-0 object-cover w-full h-full pointer-events-none"
             sizes="xs:87vw sm:786px md:1024px lg:1140px xl:1140px xxl:1140px"
             loading="lazy"
    />
    <NuxtImg v-else-if="backgroundImage || backgroundImageMobile"
             :src="backgroundImageMobile ? backgroundImageMobile : backgroundImage"
             :alt="title || ''"
             class="absolute inset-0 object-cover w-full h-full pointer-events-none"
             sizes="xs:87vw sm:786px md:1024px lg:1140px xl:1140px xxl:1140px"
             loading="lazy"
    />

    <div class="relative flex flex-col justify-between h-full">
      <div class="max-w-[320px]"
           :class="{
             'md:max-w-xl': wide
           }"
      >
        <h3 class="text-3xl font-medium"
            :class="{
              'md:text-4xl': wide // If the card is the only one in the row, make the title bigger on desktop
            }"
        >
          {{ title }}
        </h3>
        <div v-if="copy"
             class="mt-4 text-base prose prose-lg text-white prose-strong:text-white"
             v-html="copy"
        />
      </div>

      <div v-if="cta"
           class="flex text-base mt-36 md:text-lg"
           :class="[backgroundImage ? 'sm:mt-32' : 'sm:mt-16']"
      >
        <ButtonBase class="mt-2"
                    :type="cta.type"
                    :href="cta.href"
                    :label="cta.title"
                    :href-type="cta.download ? 'download' : null"
                    @click="handleClick()"
        />
      </div>

      <div v-else
           class="mt-24"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CtaType } from '../../types/button';
import { useDetectSize } from '~~/composables/detect-size';
import { useNuxtApp } from '#imports';
import { useRoute } from 'vue-router';

type CardGradientProps = {
  title: string;
  copy?: string;
  cta: CtaType;
  wide: boolean;
  backgroundImage?: string;
  backgroundGradient?: string;
  backgroundImageMobile?: string;
};

const size = useDetectSize();

const props = defineProps<CardGradientProps>();

const { $event } = useNuxtApp();
const route = useRoute();

function handleClick() {
  if (route.path.includes('partners')) return $event('Contact', { props: { 'Partners page': props.title } });
  if (route.path.includes('contact-us')) return $event('Contact', { props: { 'Contact us page': props.title } });
}
</script>
